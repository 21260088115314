import axios from 'axios'

const apiBFF = axios.create({
    // baseURL: `https://api-openinsurance.mapfre.com.br/open-insurance/consents/v1/`,
    // baseURL: `https://5w3jhsrgg2.execute-api.us-east-1.amazonaws.com/v1/bff/consent/`,
    // baseURL: `https://65pwcy7ng5.execute-api.us-east-1.amazonaws.com/v1/bff/consent/`,
    baseURL: `https://api-openinsurance.dev.mapfre.com.br/bff/consent/`,
})

export default apiBFF
